import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as PhonesAnimation1 } from "../../assets/new/userAnimation/userAnimation-01.svg";
import { ReactComponent as PhonesAnimation2 } from "../../assets/new/userAnimation/userAnimation-02.svg";
import { ReactComponent as PhonesAnimation3 } from "../../assets/new/userAnimation/userAnimation-03.svg";
import { ReactComponent as PhonesAnimation4 } from "../../assets/new/userAnimation/userAnimation-04.svg";
import { ReactComponent as PhonesAnimation5 } from "../../assets/new/userAnimation/userAnimation-05.svg";
import { ReactComponent as PhonesAnimation6 } from "../../assets/new/userAnimation/userAnimation-06.svg";
import { ReactComponent as PhonesAnimation7 } from "../../assets/new/userAnimation/userAnimation-07.svg";
import { motion } from "framer-motion/dist/framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const UserAnimation = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());
  const eleName = useRef();

  useEffect(() => {
    const el = document.querySelector("#start_id_user_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);
  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            id="start_id_user_animation"
            style={{ position: "relative", width: "100%", height: "422px" }}
          >
            {scrollval < 900 ? (
              <>
                {!enter && (
                  <>
                    <PhonesAnimation1
                      style={{ position: "relative", top: "0", left: "0" }}
                    />
                    <motion.div
                      initial={{
                        x: -85,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: scrollval / 10.5 + "px",
                      }}
                    >
                      <PhonesAnimation2
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          zIndex: "100",
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{
                        x: 70,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        right: scrollval / 11 + "px",
                      }}
                    >
                      <PhonesAnimation3
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{
                        y: -110,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: scrollval / 8.3 + "px",
                        left: "0",
                      }}
                    >
                      <PhonesAnimation4
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{
                        x: -100,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0px",
                        right: "0",
                        left: scrollval / 9 + "px",
                      }}
                    >
                      <div>
                        <PhonesAnimation5
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                          }}
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      initial={{
                        x: 100,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0px",
                        right: scrollval / 9 + "px",
                      }}
                    >
                      <PhonesAnimation6
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{
                        y: 100,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        left: "0",
                        bottom: scrollval / 9 + "px",
                      }}
                    >
                      <PhonesAnimation7
                        style={{
                          position: "absolute",
                          left: "0",
                          top: "0",
                        }}
                      />
                    </motion.div>
                  </>
                )}
              </>
            ) : (
              <>
                <PhonesAnimation1
                  style={{ position: "relative", top: "0", left: "0" }}
                />
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <PhonesAnimation2
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "100",
                    }}
                  />
                </motion.div>
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    left: "-11px",
                  }}
                >
                  <PhonesAnimation3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <PhonesAnimation4
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <div>
                    <PhonesAnimation5
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                    />
                  </div>
                </motion.div>
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    right: "0px",
                  }}
                >
                  <PhonesAnimation6
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    top: "0",
                  }}
                >
                  <PhonesAnimation7
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                    }}
                  />
                </motion.div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            id="start_id_user_animation"
            style={{ position: "relative", width: "100%", height: "422px" }}
          >
            {!enter && (
              <>
                <PhonesAnimation1
                  style={{ position: "relative", top: "0", left: "0" }}
                />
                <motion.div
                  initial={{
                    x: -150,
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left:
                      scrollval > 1600
                        ? "max(145.455px)"
                        : scrollval / 11 + "px",
                  }}
                >
                  <PhonesAnimation2
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "100",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: 150,
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    right:
                      scrollval > 1600 ? "max(162px)" : scrollval / 9.9 + "px",
                  }}
                >
                  <PhonesAnimation3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: -110,
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top:
                      scrollval > 1600
                        ? "max(116.788px)"
                        : scrollval / 13.7 + "px",
                    left: "0",
                  }}
                >
                  <PhonesAnimation4
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: -100,
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    right: "0",
                    left:
                      scrollval > 1600
                        ? "max(99.2778px)"
                        : scrollval / 16.1 + "px",
                  }}
                >
                  <div>
                    <PhonesAnimation5
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                    />
                  </div>
                </motion.div>
                <motion.div
                  initial={{
                    x: 100,
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    right:
                      scrollval > 1600
                        ? "max(99.2778px)"
                        : scrollval / 16.1 + "px",
                  }}
                >
                  <PhonesAnimation6
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: 100,
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    bottom:
                      scrollval > 1600
                        ? "max(94.4444px)"
                        : scrollval / 17 + "px",
                  }}
                >
                  <PhonesAnimation7
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                    }}
                  />
                </motion.div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UserAnimation;
