import React, { useEffect, useState } from "react";
import { ReactComponent as VideoSvg } from "../../../assets/new/socialMediaVideos/videos-01.svg";
import { ReactComponent as VideoSvg1 } from "../../../assets/new/socialMediaVideos/videos-02-02-02.svg";
import { ReactComponent as VideoSvg2 } from "../../../assets/new/socialMediaVideos/videos-03.svg";
import { ReactComponent as VideoSvg4 } from "../../../assets/new/socialMediaVideos/videos-05.svg";
import { ReactComponent as VideoSvg5 } from "../../../assets/new/socialMediaVideos/videos-06.svg";
import { ReactComponent as VideoSvg6 } from "../../../assets/new/socialMediaVideos/videos-07.svg";
import { ReactComponent as VideoSvg7 } from "../../../assets/new/socialMediaVideos/videos-08.svg";
import { ReactComponent as VideoSvg8 } from "../../../assets/new/socialMediaVideos/videos-09.svg";
import { ReactComponent as VideoSvg9 } from "../../../assets/new/socialMediaVideos/videos-10.svg";

// new once

import { ReactComponent as VideoSvgV21 } from "../../../assets/new/socialMediaVideos/theNewOne/videos-04.svg";
import { ReactComponent as VideoSvgV22 } from "../../../assets/new/socialMediaVideos/theNewOne/videos-05.svg";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SocialMediaVideos = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());

  useEffect(() => {
    const el = document.querySelector("#start_id_social_video_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  return (
    <>
      <>
        {resizeval.width > 991 ? (
          <>
            <div
              style={{ position: "relative", height: "422px" }}
              id="start_id_social_video_animation"
            >
              {scrollval < 1488 ? (
                <>
                  {!enter && (
                    <>
                      <motion.div
                        initial={{
                          scale: 0.4,
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: 0.5,
                            ease: "easeInOut",
                          },
                        }}
                      >
                        <VideoSvg
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            zIndex: " 210",
                          }}
                        />
                      </motion.div>
                      <VideoSvg1
                        className="spin video_spin"
                        style={{
                          position: "absolute",
                          right: "85px",
                          top: "75px",
                          width: " 120px",
                          height: "120px",
                        }}
                      />
                      {/* /////////////////////////// */}

                      <VideoSvgV22
                        style={{
                          position: "absolute",
                          left: "0",
                          top: "0",
                          zIndex: "0",
                        }}
                      />
                      <VideoSvg2
                        style={{
                          position: "absolute",
                          left: "0",
                          top: "0",
                        }}
                      />
                      <VideoSvgV21
                        style={{
                          position: "absolute",
                          left: "0",
                          top: "0",
                          zIndex: "200",
                        }}
                      />
                      <motion.div
                        initial={{
                          scale: 0.4,
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: 0.7,
                            ease: "easeInOut",
                          },
                        }}
                        style={{ zIndex: " 210" }}
                      >
                        <VideoSvg4
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            zIndex: " 210",
                          }}
                        />
                      </motion.div>
                      <motion.div
                        initial={{
                          scale: 0.4,
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: 0.9,
                            ease: "easeInOut",
                          },
                        }}
                        style={{ zIndex: " 210" }}
                      >
                        <VideoSvg5
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            zIndex: " 210",
                          }}
                        />
                      </motion.div>
                      <motion.div
                        initial={{
                          scale: 0.4,
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: 0.11,
                            ease: "easeInOut",
                          },
                        }}
                        style={{ zIndex: " 210" }}
                      >
                        <VideoSvg6
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            zIndex: " 210",
                          }}
                        />
                      </motion.div>
                      <motion.div
                        initial={{
                          scale: 0.4,
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: 0.13,
                            ease: "easeInOut",
                          },
                        }}
                        style={{ zIndex: " 210" }}
                      >
                        <VideoSvg7
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            zIndex: " 210",
                          }}
                        />
                      </motion.div>
                      <motion.div
                        initial={{
                          scale: 0.4,
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: 0.14,
                            ease: "easeInOut",
                          },
                        }}
                        style={{ zIndex: " 210" }}
                      >
                        <VideoSvg8
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            zIndex: " 210",
                          }}
                        />
                      </motion.div>
                      <motion.div
                        initial={{
                          x: 165,
                        }}
                        style={{
                          position: "relative",
                          top: "0",
                          zIndex: "210",
                          right: scrollval / 9 + "px",
                        }}
                      >
                        <VideoSvg9
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            zIndex: "210",
                          }}
                        />
                      </motion.div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <motion.div
                    style={{
                      position: "relative",
                      left: "0",
                      top: "0",
                      zIndex: " 100",
                    }}
                  >
                    <VideoSvg />
                  </motion.div>
                  <VideoSvg1
                    className="spin video_spin"
                    style={{
                      position: "absolute",
                      right: "85px",
                      top: "75px",
                      width: " 120px",
                      height: "120px",
                    }}
                  />
                  <VideoSvgV22
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                      zIndex: "0",
                    }}
                  />
                  <VideoSvg2
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                    }}
                  />
                  <VideoSvgV21
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                      zIndex: "200",
                    }}
                  />
                  {/* <VideoSvg3
                    style={{ position: "absolute", left: "0", top: "0" }}
                  /> */}
                  <motion.div>
                    <VideoSvg4
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: " 100",
                      }}
                    />
                  </motion.div>
                  <motion.div>
                    <VideoSvg5
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: " 100",
                      }}
                    />
                  </motion.div>
                  <motion.div>
                    <VideoSvg6
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: " 100",
                      }}
                    />
                  </motion.div>
                  <motion.div>
                    <VideoSvg7
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: " 100",
                      }}
                    />
                  </motion.div>
                  <motion.div>
                    <VideoSvg8
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: " 100",
                      }}
                    />
                  </motion.div>
                  <motion.div>
                    <VideoSvg9
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: " 100",
                      }}
                    />
                  </motion.div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                position: "relative",
                transform: "scale(1.3)",
              }}
              id="start_id_social_video_animation"
            >
              <motion.div
                initial={{
                  scale: 0.4,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.5,
                    ease: "easeInOut",
                  },
                }}
              >
                <VideoSvg
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: " 210",
                  }}
                />
              </motion.div>
              <VideoSvg1
                className="spin video_spin"
                style={{
                  position: "absolute",
                  right: "85px",
                  top: "75px",
                  width: " 120px",
                  height: "120px",
                }}
              />
              {/* /////////////////////////// */}

              <VideoSvgV22
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  zIndex: "0",
                }}
              />
              <VideoSvg2
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                }}
              />
              <VideoSvgV21
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  zIndex: "200",
                }}
              />
              <motion.div
                initial={{
                  scale: 0.4,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.7,
                    ease: "easeInOut",
                  },
                }}
                style={{ zIndex: " 210" }}
              >
                <VideoSvg4
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: " 210",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  scale: 0.4,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.9,
                    ease: "easeInOut",
                  },
                }}
                style={{ zIndex: " 210" }}
              >
                <VideoSvg5
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: " 210",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  scale: 0.4,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.11,
                    ease: "easeInOut",
                  },
                }}
                style={{ zIndex: " 210" }}
              >
                <VideoSvg6
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: " 210",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  scale: 0.4,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.13,
                    ease: "easeInOut",
                  },
                }}
                style={{ zIndex: " 210" }}
              >
                <VideoSvg7
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: " 210",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  scale: 0.4,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.14,
                    ease: "easeInOut",
                  },
                }}
                style={{ zIndex: " 210" }}
              >
                <VideoSvg8
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: " 210",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  x: 270,
                }}
                style={{
                  position: "relative",
                  top: "0",
                  zIndex: "210",
                  right:
                    scrollval > 2400 ? "max(266.444px)" : scrollval / 9 + "px",
                }}
              >
                <VideoSvg9
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "210",
                  }}
                />
              </motion.div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default SocialMediaVideos;
