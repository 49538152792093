import React from "react";
import LiveRouteLogo from "../../assets/images/logoLiveRoute.png";
import LiveRoute1 from "../../assets/images/1.png";
import LiveRoute2 from "../../assets/images/2.png";
import LiveRoute3 from "../../assets/images/3.png";
import LiveRoute4 from "../../assets/images/5.png";

const LiveRoute = () => {
  return (
    <>
      <div className="liveRouteSection">
        <div className="container">
          <h2 style={{ fontSize: "30px" }}>Some Of Our Projects</h2>
        </div>
        <div className="container background">
          <br />
          <img src={LiveRouteLogo} alt="" />
          <div className="appImages">
            <img src={LiveRoute1} alt="" />
            <img src={LiveRoute2} alt="" />
            <img src={LiveRoute3} alt="" />
            <img src={LiveRoute4} alt="" />
          </div>
          <p>
            See your children's school bus in real time! Never wait or miss the
            bus
          </p>
          <a href="https://liveroute.app/" alt="logo" target="_blank">
            <button>EXPLORE</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default LiveRoute;
