import React, { useEffect, useState } from "react";
import LogoLight from "../../assets/images/white_logo.png";

const initData1 = {
  heading: "Oramalab",
  content_1: "Tap into the internet’s infinite potential",
  content_2: "Bridge the gap to your customers",
  content_3: "Engineering to meet your needs",
  content_4: "Apps people gravitate towards",
  content_5: "Attract your audience",
  content_6: "Rapid growth for your organization",
  content_7: "Captivate your audience",
  content_8: "Solutions for your enterprise",
  text: "Explore our wide range of services to help you grow your business.",
  btnText: "Media Services",
  btnTextTwo: "Software Services",
};

const WelcomeNine = () => {
  const [initData, setInitData] = useState({});

  useEffect(() => {
    setInitData(initData1);
  }, []);

  return (
    <section
      id="home"
      className="section welcome-area d-flex align-items-center h-100vh pt-0"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Welcome Intro Start */}
          <div className="col-12 col-md-10 col-lg-8">
            <div className="welcome-intro text-center mt-0">
              <h1 className="text-white">
                <img width={400} height={250} src={LogoLight} />
              </h1>
              <h2 className="cd-headline clip fw-3 mt-2 mt-sm-3">
                <span className="cd-words-wrapper">
                  <b className="text-white is-visible">{initData.content_1}</b>
                  <b className="text-white">{initData.content_2}</b>
                  <b className="text-white">{initData.content_3}</b>
                  <b className="text-white">{initData.content_4}</b>
                  <b className="text-white">{initData.content_5}</b>
                  <b className="text-white">{initData.content_6}</b>
                  <b className="text-white">{initData.content_7}</b>
                  <b className="text-white">{initData.content_8}</b>
                </span>
              </h2>
              <p className="text-white my-3">{initData.text}</p>
              <div className="button-group">
                <a href="/software" className="btn btn-bordered-white">
                  {initData.btnTextTwo}
                </a>
                <a href="/media" className="btn btn-bordered-white">
                  <span>{initData.btnText}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shapes-container">
        <div className="wave-animation">
          {/* Wave Animation */}
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x={48}
                y={0}
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x={48}
                y={3}
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x={48}
                y={5}
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x={48} y={7} fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </section>
  );
};

export default WelcomeNine;
