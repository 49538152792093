import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as NewStatisticIcon2 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-02.svg";
import { ReactComponent as NewStatisticIcon3 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-03.svg";
import { ReactComponent as NewStatisticIcon4 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-04.svg";
import { ReactComponent as NewStatisticIcon5 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-05.svg";
import { ReactComponent as NewStatisticIcon6 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-06.svg";
import { ReactComponent as NewStatisticIcon7 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-07.svg";
import { ReactComponent as NewStatisticIcon8 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-08.svg";
import { ReactComponent as NewStatisticIcon9 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-09.svg";
import { ReactComponent as NewStatisticIcon99 } from "../../../assets/new/socialMediaStatistic/newOnce/socialmediastatisti-09-99-09-09.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Frame, useCycle } from "framer";
import { convertToObject } from "typescript";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SocialMediaStatistic = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());
  useEffect(() => {
    const el = document.querySelector("#start_id_media_statistic_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  const [animate, cycle] = useCycle(
    { y: -150, x: 100, opacity: 0, scale: 0.1 },
    { y: 0, x: 0, opacity: 1, scale: 1 }
  );

  const [animate1, cycle1] = useCycle(
    { opacity: 0, scale: 0.1 },
    { opacity: 1, scale: 1 }
  );

  const [animate2, cycle2] = useCycle(
    { x: 200, opacity: 0, scale: 0.1 },
    { x: 0, opacity: 1, scale: 1 }
  );

  const [animate3, cycle3] = useCycle(
    { y: -80, opacity: 0, scale: 0.1 },
    { y: 0, opacity: 1, scale: 1 }
  );

  const [animate0, cycle0] = useCycle();

  var style1 = {
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: scrollval / 8 + "px",
    left: "0",
    zIndex: "9",
  };

  var style2 = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "max(-240px)",
    left: "0",
    zIndex: "9",
  };

  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            style={{ position: "relative", height: "475px" }}
            id="start_id_media_statistic_animation"
          >
            {scrollval < 1199 ? (
              <>
                {/* {!enter && ( */}
                <>
                  <NewStatisticIcon2
                    style={{
                      height: "100%",
                      position: "relative",
                      top: "0 ",
                      left: "0",
                    }}
                  />
                  <NewStatisticIcon3
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                  <NewStatisticIcon4
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "11",
                    }}
                  />
                  <motion.div
                    animate={animate}
                    transition={{ duration: 1 }}
                    onTap={() => cycle()}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      zIndex: "11",
                    }}
                  >
                    <NewStatisticIcon5
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                    />
                  </motion.div>
                  <motion.div
                    animate={animate1}
                    transition={{ duration: 1 }}
                    onTap={() => cycle1()}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      zIndex: "11",
                    }}
                  >
                    <NewStatisticIcon6
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                    />
                  </motion.div>
                  <motion.div
                    animate={animate2}
                    transition={{ duration: 1 }}
                    onTap={() => cycle2()}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      zIndex: "11",
                    }}
                  >
                    <NewStatisticIcon7
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                    />
                  </motion.div>
                  <motion.div
                    animate={animate3}
                    transition={{ duration: 1 }}
                    onTap={() => cycle3()}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      zIndex: "11",
                    }}
                  >
                    <NewStatisticIcon8
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "20",
                      }}
                    />
                  </motion.div>
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: "0px",
                      bottom: "0",
                      zIndex: "9",
                    }}
                  ></div>
                  <motion.div
                    initial={{
                      y: 150,
                    }}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      bottom: scrollval / 8 + "px",
                      left: "0px",
                      zIndex: "9",
                    }}
                  >
                    <NewStatisticIcon99
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "9",
                      }}
                    />
                  </motion.div>
                </>
                {/* )} */}
              </>
            ) : (
              <>
                <NewStatisticIcon2
                  style={{
                    height: "100%",
                    position: "relative",
                    top: "0 ",
                    left: "0",
                  }}
                />
                <NewStatisticIcon3
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                />
                <NewStatisticIcon4
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "11",
                  }}
                />
                <motion.div
                  initial={{
                    y: -150,
                    x: 100,
                    opacity: 0,
                    scale: 0.1,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      ease: "easeInOut",
                    },
                  }}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon5
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    opacity: 0,
                    scale: 0.1,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      ease: "easeInOut",
                    },
                  }}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon6
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: 200,
                    opacity: 0,
                    scale: 0.1,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      ease: "easeInOut",
                    },
                  }}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon7
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: -80,
                    opacity: 0,
                    scale: 0.1,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      ease: "easeInOut",
                    },
                  }}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon8
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "20",
                    }}
                  />
                </motion.div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0px",
                    bottom: "0",
                    zIndex: "9",
                  }}
                >
                  <NewStatisticIcon99
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "9",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ position: "relative", height: "475px" }}
            id="start_id_media_statistic_animation"
          >
            {!enter && (
              <>
                <NewStatisticIcon2
                  style={{
                    height: "100%",
                    position: "relative",
                    top: "0 ",
                    left: "-100px",
                  }}
                />
                <NewStatisticIcon3
                  style={{
                    height: "90%",
                    position: "absolute",
                    top: "0",
                    left: "-100px",
                  }}
                />
                <NewStatisticIcon4
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "-100px",
                    zIndex: "11",
                  }}
                />
                <motion.div
                  animate={scrollval >= 1899 ? animate0 : animate}
                  transition={{ duration: 1 }}
                  onTap={() => (scrollval >= 1899 ? cycle0() : cycle())}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon5
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "-100px",
                    }}
                  />
                </motion.div>
                <motion.div
                  animate={scrollval >= 1899 ? animate0 : animate1}
                  transition={{ duration: 1 }}
                  onTap={() => (scrollval >= 1899 ? cycle0() : cycle1())}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon6
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "-100px",
                    }}
                  />
                </motion.div>
                <motion.div
                  animate={scrollval >= 1899 ? animate0 : animate2}
                  transition={{ duration: 1 }}
                  onTap={() => (scrollval >= 1899 ? cycle0() : cycle2())}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon7
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "-100px",
                    }}
                  />
                </motion.div>
                <motion.div
                  animate={scrollval >= 1899 ? animate0 : animate3}
                  transition={{ duration: 1 }}
                  onTap={() => (scrollval >= 1899 ? cycle0() : cycle3())}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "11",
                  }}
                >
                  <NewStatisticIcon8
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "-100px",
                      zIndex: "20",
                    }}
                  />
                </motion.div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0px",
                    bottom: "0",
                    zIndex: "9",
                  }}
                ></div>
                <motion.div
                  className="test_max_position"
                  initial={{
                    y: 240,
                  }}
                  style={scrollval > 1900 ? style2 : style1}
                >
                  <NewStatisticIcon99
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "-100px",
                      zIndex: "9",
                    }}
                  />
                </motion.div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SocialMediaStatistic;
