import React from "react";
// testing
// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";
import "react-toastify/dist/ReactToastify.css";

console.log("orama-lap");

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;
