import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeNine from "../themes/theme-nine";
import AboutUs from "../components/innerSection/about/about-us";
import Faq from "../components/innerSection/faq/faq";
import Newsletter from "../components/innerSection/newsletter/newsletter";
import Contact from "../components/innerSection/contact/contact";
import BlogTwoColumn from "../components/blogs/blog-two-column";
import BlogDetailsLeftSidebar from "../components/blogs/blog-details-left-sidebar";
import Software from "../components/software/Software";
import Media from "../components/software/Media";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeNine} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/software" component={Software} />
            <Route exact path="/media" component={Media} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/newsletter" component={Newsletter} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/blog-two-column" component={BlogTwoColumn} />
            <Route exact path="/blog-details-left-sidebar" component={BlogDetailsLeftSidebar} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;