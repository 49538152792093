import React, { useEffect, useState } from "react";
import { ReactComponent as CloudAnimation02 } from "../../assets/images/CloudAnimationNew-02.svg";
import { ReactComponent as CloudAnimation03 } from "../../assets/images/CloudAnimationNew-03.svg";
import { ReactComponent as CloudAnimation04 } from "../../assets/images/CloudAnimationNew-04.svg";
import { ReactComponent as CloudAnimation05 } from "../../assets/images/CloudAnimationNew-05.svg";
import { ReactComponent as CloudAnimation06 } from "../../assets/images/Wavy_Tech-28_Single-07-06.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const ServiceAnimationCloud = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  // useEffect(() => {
  //   const el = document.querySelector("#start_id_cloud_animation");
  //   const observer = new window.IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setEnter(false);
  //         return;
  //       }
  //       setEnter(true);
  //     },
  //     {
  //       root: null,
  //       threshold: 0,
  //     }
  //   );
  //   observer.observe(el);
  // }, []);

  return (
    <>
      {resizeval.width > 991 ? (
        <>
          {scrollval > 2000 ? (
            <>
              <div
                style={{ position: "relative", overflow: "hidden" }}
                id="start_id_cloud_animation"
              >
                {/* ================================ */}
                <CloudAnimation02
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
                {/* ================================ */}
                <motion.div
                  // initial={{
                  //   x: 0,
                  // }}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: scrollval / 25 + "px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CloudAnimation03
                    id="getFrontScreenCloud1"
                    style={{
                      position: "absolute",
                      top: "-80px",
                      left: "-230px",
                    }}
                  />
                </motion.div>
                {/* ================================ */}
                <motion.div
                  // initial={{
                  //   x: 0,
                  // }}
                  // animate={{
                  //   x: -10,
                  //   transition: {
                  //     duration: 3,
                  //     repeat: Infinity,
                  //     repeatType: "reverse",
                  //     ease: "easeInOut",
                  //   },
                  // }}
                  style={{
                    position: "relative",
                    top: "0",
                    right: scrollval / 25 + "px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CloudAnimation04
                    id="getFrontScreenCloud"
                    style={{
                      position: "relative",
                      top: "0",
                      right: "-80px",
                      zIndex: "50",
                    }}
                  />
                </motion.div>
                {/* ================================ */}

                <motion.div
                  // initial={{
                  //   x: 0,
                  // }}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: scrollval / 20 + "px",
                    width: "82%",
                    height: "100%",
                  }}
                >
                  <CloudAnimation05
                    id="getFrontScreenCloud1"
                    style={{
                      position: "absolute",
                      top: "-25px",
                      left: "-130px",
                    }}
                  />
                </motion.div>
                {/* ================================ */}
                <motion.div
                  // initial={{
                  //   x: 0,
                  // }}
                  // animate={{
                  //   x: -10,
                  //   transition: {
                  //     duration: 3,
                  //     repeat: Infinity,
                  //     repeatType: "reverse",
                  //     ease: "easeInOut",
                  //   },
                  // }}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: scrollval / 25 + "px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CloudAnimation06
                    className="spin"
                    style={{
                      position: "absolute",
                      top: "21%",
                      // left: "44%",
                      right: "150px",
                      zIndex: "1000",
                      width: "13%",
                      height: "80px",
                      fill: "white",
                      zIndex: "100",
                    }}
                  />
                </motion.div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ position: "relative", overflow: "hidden" }}
                id="start_id_cloud_animation"
              >
                {/* ================================ */}
                <CloudAnimation02
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
                {/* ================================ */}
                <motion.div
                  initial={{
                    x: -20,
                  }}
                  animate={{
                    x: 10,
                    transition: {
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    },
                  }}
                >
                  <CloudAnimation03
                    id="getFrontScreenCloud1"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                    }}
                  />
                </motion.div>
                {/* ================================ */}
                <motion.div
                  initial={{
                    x: 10,
                  }}
                  animate={{
                    x: -10,
                    transition: {
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    },
                  }}
                >
                  <CloudAnimation04
                    id="getFrontScreenCloud"
                    style={{
                      position: "relative",
                      top: "0",
                      right: "0",
                      zIndex: "50",
                    }}
                  />
                </motion.div>
                {/* ================================ */}

                <motion.div
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  initial={{
                    x: -10,
                  }}
                  animate={{
                    x: 20,
                    transition: {
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    },
                  }}
                >
                  <CloudAnimation05
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      zIndex: "300",
                    }}
                  />
                </motion.div>
                {/* ================================ */}
                <motion.div
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  initial={{
                    x: 10,
                  }}
                  animate={{
                    x: -10,
                    transition: {
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    },
                  }}
                >
                  <CloudAnimation06
                    className="spin"
                    style={{
                      position: "absolute",
                      top: "21%",
                      left: "44%",
                      zIndex: "1000",
                      width: "13%",
                      height: "80px",
                      fill: "white",
                    }}
                  />
                </motion.div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{ position: "relative", overflow: "hidden" }}
            id="start_id_cloud_animation"
          >
            <>
              <CloudAnimation02
                style={{ position: "absolute", top: "0", left: "0" }}
              />
              <motion.div
                initial={{
                  x: -140,
                }}
                style={{
                  position: "absolute",
                  top: "0",
                  left:
                    scrollval > 4250 ? "max(141.667px)" : scrollval / 30 + "px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CloudAnimation03
                  id="getFrontScreenCloud1"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  x: 140,
                }}
                // animate={{
                //   x: -10,
                //   transition: {
                //     duration: 3,
                //     repeat: Infinity,
                //     repeatType: "reverse",
                //     ease: "easeInOut",
                //   },
                // }}
                style={{
                  position: "relative",
                  top: "0",
                  right:
                    scrollval > 4250 ? "max(141.667px)" : scrollval / 30 + "px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CloudAnimation04
                  id="getFrontScreenCloud"
                  style={{
                    position: "relative",
                    top: "0px",
                    right: "0px",
                    zIndex: "50",
                  }}
                />
              </motion.div>

              <motion.div
                initial={{
                  x: -140,
                }}
                style={{
                  position: "absolute",
                  top: "22px",
                  left: scrollval > 4250 ? "max(170px)" : scrollval / 25 + "px",
                  width: "82%",
                  height: "100%",
                }}
              >
                <CloudAnimation05
                  id="getFrontScreenCloud1"
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{
                  x: 170,
                }}
                // animate={{
                //   x: -10,
                //   transition: {
                //     duration: 3,
                //     repeat: Infinity,
                //     repeatType: "reverse",
                //     ease: "easeInOut",
                //   },
                // }}
                style={{
                  position: "absolute",
                  top: "0",
                  right:
                    scrollval > 4250 ? "max(170px)" : scrollval / 25 + "px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CloudAnimation06
                  className="spin"
                  style={{
                    position: "absolute",
                    top: "21%",
                    // left: "44%",
                    right: "44%",
                    zIndex: "1000",
                    width: "13%",
                    height: "80px",
                    fill: "white",
                    zIndex: "100",
                  }}
                />
              </motion.div>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default ServiceAnimationCloud;
