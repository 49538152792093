import React, { useEffect, useState } from "react";
import axios from "axios";
import whiteLogo from "../../assets/images/white_logo.png";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { ReactComponent as FaceIcon } from "../../assets/footericons/facebook.svg";

import { ReactComponent as InstaIcon } from "../../assets/footericons/instagram.svg";

import { ReactComponent as LinkedinIcon } from "../../assets/footericons/linkedin.svg";

import { ReactComponent as TwitterIcon } from "../../assets/footericons/twitter.svg";

import { ReactComponent as Phone } from "../../assets/images/telephone-call.svg";

import { ReactComponent as Location } from "../../assets/images/location.svg";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneFooterSection";

const Footer = () => {
  const [data, setData] = useState({});
  const [iconList, setIconList] = useState([]);
  const [footerList_1, setFooterList_1] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
        setIconList(res.data.iconList);
        setFooterList_1(res.data.footerList_1);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <footer className="section footer-area footer-bg">
      {/* Footer Top */}
      <div style={{ padding: "25px 0" }} className="footer-top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-sm-6 col-lg-8">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Logo */}
                <a className="navbar-brand" href="/">
                  <img className="logo" src={whiteLogo} alt="Logo" />
                </a>
                <p className="text-white-50 mt-2 mb-3">
                  When Businesses Go Viral
                </p>
                <p style={{ marginBottom: "10px" }}>
                  <Phone
                    style={{
                      fill: "gray",
                      width: "25px ",
                      height: "25px",
                      marginRight: ".5rem",
                    }}
                  />
                  <a href="tel:+14703553442" style={{ color: "white" }}>
                    +1 (470) 355-3442
                  </a>
                </p>
                <p
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Location
                    style={{
                      fill: "gray",
                      width: "25px ",
                      height: "25px",
                      marginRight: ".5rem",
                    }}
                  />{" "}
                  <a
                    style={{ color: "white" }}
                    href="https://www.google.com/maps/place/Atlanta,+GA,+USA/@33.7674904,-84.4901703,12z/data=!3m1!4b1!4m5!3m4!1s0x88f5045d6993098d:0x66fede2f990b630b!8m2!3d33.748752!4d-84.3876845"
                    target="_blank"
                  >
                    Atlanta, Ga
                  </a>
                </p>
                {/* Social Icons */}
                <div className="social-icons d-flex">
                  <a
                    style={{ background: "#131418" }}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://facebook.com/OramaLab/"
                  >
                    <FaceIcon style={{ width: "80%" }} />
                    {/* <FaFacebookSquare
                      style={{ color: "#ffffff", fontSize: "33px" }}
                    />
                    <FaFacebookSquare
                      style={{ color: "#ffffff", fontSize: "33px" }}
                    /> */}
                  </a>
                  <a
                    style={{ background: "#131418" }}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://www.instagram.com/oramalab/"
                  >
                    <InstaIcon style={{ width: "80%" }} />
                  </a>
                  <a
                    style={{ background: "#131418" }}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://twitter.com/oramalab"
                  >
                    <TwitterIcon style={{ width: "80%" }} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Footer Title */}
                <h3 className="footer-title text-white mb-2">
                  {data.linkText_1}
                </h3>
                <ul>
                  <li className="py-2">
                    <a className="text-white-50" href="/">
                      Home
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/software">
                      Software Services
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/media">
                      Media Services
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/blog-two-column">
                      Blog
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/#contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Copyright Area */}
              <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                {/* Copyright Left */}
                <div className="copyright-left text-white-50">
                  &copy; Copyrights {new Date().getFullYear()} Orama Lab. All
                  rights reserved.
                </div>
                {/* Copyright Right */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
