import React, { useEffect, useState } from "react";
import axios from "axios";

const SoftwareServices = ({
  title,
  body,
  ImageComp,
  isReversed,
  tag1,
  tag2,
  tag3,
  tag4,
}) => {
  var style1 = {
    fontSize: "13px",
  };

  return (
    <>
      <section className="section about-app-area">
        <div className="container">
          <div
            style={{ flexDirection: isReversed ? "row-reverse" : "" }}
            className={"row align-items-center"}
          >
            <div className="col-12 col-lg-6" style={{ paddingRight: "0" }}>
              {/* Section Text */}
              <div className="about-text">
                {/* Headings */}
                <div className="headings d-flex align-items-center mb-4">
                  <h2 style={{ fontSize: "40px" }} className="text-capitalize">
                    {title}
                  </h2>
                </div>
                <p className="my-3">{body}</p>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <p className="newP" style={{ margin: "1rem" }}>
                    {tag1}
                  </p>
                  <p className="newP">{tag2}</p>
                  <p className="newP">{tag3}</p>
                  {!tag4 ? (
                    <></>
                  ) : (
                    <>
                      <p className="newP">{tag4}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 service_section_css_style">
              {/* Section Thumb */}
              <div className="about-thumb text-center d-lg-block" id="start_id">
                {ImageComp}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SoftwareServices;
