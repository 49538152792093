import React, { Component, useEffect, useState } from 'react';

const initData1 = {
    heading: "Start your 14 days free trial",
    content: "No Credit card required",
    btnText: "Get Started"
}

const SubscribeOne = () => {

    const [initData, setInitData] = useState({})

    useEffect(() => {
        setInitData(initData1)
    }, [])
    
    return (
        <section className="start-free-area">
            <div className="container">
                <div className="col-12">
                {/* Start Free Content */}
                <div className="start-free-content d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between shadow-lg" data-aos="fade-up">
                    {/* Start Free Content */}
                    <div className="start-free-text">
                    <h2 className="mb-2">{initData.heading}</h2>
                    <span>{initData.content}</span>
                    </div>
                    {/* Start Free Button */}
                    <div className="start-free-btn mt-4 mt-lg-0">
                    <a href="#" className="btn btn-bordered"><span>{initData.btnText}</span></a>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default SubscribeOne;