import React from "react";

const SoftwareServices = ({ title, body, ImageComp, isReversed }) => {

    return (
        <>
            <section className="section about-app-area">
                <div className="container">
                    <div style={{ flexDirection: isReversed ? "row-reverse" : "" }} className={'row align-items-center'}>
                        <div className="col-12 col-lg-6">
                            {/* Section Text */}
                            <div className="about-text">
                                {/* Headings */}
                                <div className="headings d-flex align-items-center mb-4">
                                    <h2 className="text-capitalize">{title}</h2>
                                </div>
                                <p style={{ fontSize: "16px" }} className="my-3">{body}</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 service_section_css_style">
                            {/* Section Thumb */}
                            <div className="about-thumb text-center d-lg-block" id="start_id">
                                {ImageComp}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SoftwareServices;
