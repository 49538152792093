import React, { useEffect, useState } from "react";

const initData1 = {
  iconClass: "icofont-bubble-up",
};

const ScrollUp = () => {
  const [initData, setInitData] = useState({});
  useEffect(() => {
    setInitData(initData1);
  }, []);

  return (
    <div id="scrollUp" title="Scroll To Top">
      <i className={initData.iconClass} />
    </div>
  );
};

export default ScrollUp;
