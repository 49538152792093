import React, { useEffect, useState } from "react";
import { ReactComponent as Animate2 } from "../../assets/images/CloudAnimationNew-02-02-02-02.svg";
import { ReactComponent as Animate3 } from "../../assets/images/Animate3-03.svg";
import { ReactComponent as Animate4 } from "../../assets/images/Animate3-04.svg";
import { ReactComponent as Animate5 } from "../../assets/images/Animate3-05.svg";
import { ReactComponent as Animate6 } from "../../assets/images/Animate3-06.svg";
import { ReactComponent as Animate7 } from "../../assets/images/Animate3-07.svg";
import { motion } from "framer-motion/dist/framer-motion";

import NewAnimation8 from "../../assets/new/svg-08-08.png";
import NewAnimation9 from "../../assets/new/svg-08-09.png";
import NewAnimation10 from "../../assets/new/svg-08-10.png";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Animation3 = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());

  useEffect(() => {
    const el = document.querySelector("#start_id_idea_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            style={{ position: "relative", height: "422px" }}
            id="start_id_idea_animation"
          >
            {/* {!enter && ( */}
            <>
              <Animate2 style={{ position: "relative", top: "0", left: "0" }} />
              <motion.div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                initial={{
                  opacity: 0,
                  x: -30,
                  scale: 0.5,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 1,
                    ease: "easeInOut",
                  },
                }}
              >
                <Animate3
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
              </motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                initial={{
                  opacity: 0,
                  x: -10,
                  y: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  y: 0,
                  transition: {
                    duration: 1,
                    ease: "easeInOut",
                  },
                }}
              >
                <Animate4
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
              </motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                initial={{
                  opacity: 0,
                  x: 10,
                  y: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  y: 0,
                  transition: {
                    duration: 1,
                    ease: "easeInOut",
                  },
                }}
              >
                <Animate5
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
              </motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                initial={{
                  opacity: 0,
                  x: -10,
                  y: 10,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  y: 0,
                  transition: {
                    duration: 1,
                    ease: "easeInOut",
                  },
                }}
              >
                <Animate6
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
              </motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                initial={{
                  opacity: 0,
                  x: 20,
                  y: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  y: 0,
                  transition: {
                    duration: 1,
                    ease: "easeInOut",
                  },
                }}
              >
                <Animate7
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
              </motion.div>
              <motion.img
                initial={{
                  x: 130,
                }}
                id="getImagesPosition"
                src={NewAnimation8}
                alt=""
                className="image-1"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: scrollval / 20 + "px",
                  zIndex: "100",
                }}
              />
              <motion.img
                initial={{
                  x: -130,
                }}
                id="getImagesPosition1"
                src={NewAnimation9}
                alt=""
                className="image-2"
                style={{
                  position: "absolute",
                  top: "0px",
                  left: scrollval / 20 + "px",
                  zIndex: "100",
                }}
              />
              <motion.img
                initial={{
                  x: -130,
                }}
                id="getImagesPosition2"
                src={NewAnimation10}
                alt=""
                className="image-3"
                style={{
                  position: "absolute",
                  top: "0px",
                  left: scrollval / 20 + "px",
                  zIndex: "100",
                }}
              />
            </>
            {/* )} */}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ position: "relative", height: "422px" }}
            id="start_id_idea_animation"
          >
            <Animate2 style={{ position: "relative", top: "0", left: "0" }} />
            <motion.div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              initial={{
                opacity: 0,
                x: -30,
                scale: 0.5,
              }}
              animate={{
                x: 0,
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
            >
              <Animate3 style={{ position: "absolute", top: "0", left: "0" }} />
            </motion.div>
            <motion.div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              initial={{
                opacity: 0,
                x: -10,
                y: -20,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
            >
              <Animate4 style={{ position: "absolute", top: "0", left: "0" }} />
            </motion.div>
            <motion.div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              initial={{
                opacity: 0,
                x: 10,
                y: -20,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
            >
              <Animate5 style={{ position: "absolute", top: "0", left: "0" }} />
            </motion.div>
            <motion.div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              initial={{
                opacity: 0,
                x: -10,
                y: 10,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
            >
              <Animate6 style={{ position: "absolute", top: "0", left: "0" }} />
            </motion.div>
            <motion.div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              initial={{
                opacity: 0,
                x: 20,
                y: -20,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                },
              }}
            >
              <Animate7 style={{ position: "absolute", top: "0", left: "0" }} />
            </motion.div>
            <motion.img
              initial={{
                x: 200,
              }}
              id="getImagesPosition"
              src={NewAnimation8}
              alt=""
              className="image-1"
              style={{
                position: "absolute",
                top: "0px",
                right: scrollval > 3500 ? "175px" : scrollval / 20 + "px",
                zIndex: "100",
              }}
            />
            <motion.img
              initial={{
                x: -200,
              }}
              id="getImagesPosition1"
              src={NewAnimation9}
              alt=""
              className="image-2"
              style={{
                position: "absolute",
                top: "0px",
                left: scrollval > 3500 ? "175px" : scrollval / 20 + "px",
                zIndex: "100",
              }}
            />
            <motion.img
              initial={{
                x: -200,
              }}
              id="getImagesPosition2"
              src={NewAnimation10}
              alt=""
              className="image-3"
              style={{
                position: "absolute",
                top: "0px",
                left: scrollval > 3500 ? "175px" : scrollval / 20 + "px",
                zIndex: "100",
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Animation3;
