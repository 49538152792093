import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Charticon1 } from "../../../assets/new/socialMediaChrt/socialMediaChartAn-04-04.svg";
import { ReactComponent as Charticon2 } from "../../../assets/new/socialMediaChrt/socialMediaChartAn-02.svg";
import { ReactComponent as Charticon3 } from "../../../assets/new/socialMediaChrt/socialMediaChartAn-03.svg";
import { ReactComponent as Charticon4 } from "../../../assets/new/socialMediaChrt/socialMediaChartAn-04.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const SocialMediaChart = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());
  useEffect(() => {
    const el = document.querySelector("#start_id_social_chart_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);
  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            style={{ position: "relative", height: "422px" }}
            id="start_id_social_chart_animation"
          >
            {scrollval < 2900 ? (
              <>
                {!enter && (
                  <>
                    <div>
                      <Charticon1
                        className="spin"
                        style={{
                          position: "relative",
                          top: "80px",
                          left: "115px",
                          zIndex: "120",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </div>
                    <motion.div
                      initial={{
                        x: 320,
                      }}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: scrollval / 10 + "px",
                        zIndex: "200",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Charticon2 />
                    </motion.div>
                    <Charticon3
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "100",
                      }}
                    />
                    <motion.div
                      initial={{
                        y: 300,
                      }}
                      style={{
                        position: "absolute",
                        bottom: scrollval / 10 + "px",
                        left: "0",
                        zIndex: "200",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Charticon4 />
                    </motion.div>
                  </>
                )}
              </>
            ) : (
              <>
                <div>
                  <Charticon1
                    className="spin"
                    style={{
                      position: "relative",
                      top: "80px",
                      left: "115px",
                      zIndex: "120",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </div>
                <motion.div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-30px",
                    zIndex: "200",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Charticon2 />
                </motion.div>
                <Charticon3
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "100",
                  }}
                />
                <motion.div
                  style={{
                    position: "absolute",
                    bottom: "-13px",
                    left: "0",
                    zIndex: "200",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Charticon4 />
                </motion.div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              transform: "scale(1.4)",
            }}
            id="start_id_social_chart_animation"
          >
            <div>
              <Charticon1
                className="spin1"
                style={{
                  position: "relative",
                  left: "25%",
                  top: "30px",
                  zIndex: "120",
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
            <motion.div
              initial={{
                x: 440,
              }}
              style={{
                position: "absolute",
                top: "0",
                right:
                  scrollval > 4277 ? "max(427.7px)" : scrollval / 10 + "px",
                zIndex: "200",
                width: "100%",
                height: "100%",
              }}
            >
              <Charticon2 />
            </motion.div>
            <Charticon3
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "100",
              }}
            />
            <motion.div
              initial={{
                y: 420,
              }}
              style={{
                position: "absolute",
                bottom:
                  scrollval > 4277 ? "max(427.7px)" : scrollval / 10 + "px",
                left: "0",
                zIndex: "200",
                width: "100%",
                height: "100%",
              }}
            >
              <Charticon4 />
            </motion.div>
          </div>
        </>
      )}
    </>
  );
};
export default SocialMediaChart;
