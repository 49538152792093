import React, { useEffect, useState } from "react";
import { ReactComponent as BrandIcon4 } from "../../../assets/new/brandanimation/brandAnimation-04-04.svg";
import { ReactComponent as NewBrand1 } from "../../../assets/new/brandanimation/brandAnimationnew-01-05.svg";
import { ReactComponent as NewBrand2 } from "../../../assets/new/brandanimation/brandAnimationnew-01-06.svg";
import { ReactComponent as NewBrand3 } from "../../../assets/new/brandanimation/brandAnimationnew-01-07.svg";
import { ReactComponent as SingleMobile } from "../../../assets/new/brand/singleMobile.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const BrandAnimation = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());

  useEffect(() => {
    const el = document.querySelector("#start_id_media_statistic_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            style={{ position: "relative", height: "422px" }}
            id="start_id_media_statistic_animation"
          >
            {scrollval < 306 ? (
              <>
                {!enter && (
                  <>
                    <motion.div
                      // initial={{
                      //   scale: 0.5,
                      // }}
                      // animate={{
                      //   scale: 1,
                      //   transition: {
                      //     duration: 0.7,
                      //     ease: "easeInOut",
                      //   },
                      // }}
                      style={{ position: "relative", top: "0", left: "0" }}
                    >
                      <BrandIcon4
                        style={{ position: "relative", top: "0", left: "0" }}
                      />
                    </motion.div>
                    <motion.div
                      className="brand_rotation_con"
                      initial={{
                        rotate: 10,
                        x: 80,
                        y: 20,
                      }}
                    >
                      <NewBrand1
                        className="brand_rotation"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "80px",
                          transform: `rotate(${scrollval / 20}deg)`,
                        }}
                      />
                    </motion.div>
                    <motion.div className="brand_rotation_con">
                      <NewBrand2
                        className="brand_rotation num_1"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "80px",
                        }}
                      />
                    </motion.div>
                    <motion.div
                      className="brand_rotation_con"
                      initial={{
                        rotate: 100,
                        x: 280,
                        y: 230,
                      }}
                    >
                      <NewBrand3
                        className="brand_rotation num_2"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "80px",
                          transform: `rotate(-${scrollval / 20}deg)`,
                        }}
                      />
                    </motion.div>
                  </>
                )}
              </>
            ) : (
              <>
                <motion.div
                  style={{ position: "relative", top: "0", left: "0" }}
                >
                  <BrandIcon4
                    style={{ position: "relative", top: "0", left: "0" }}
                  />
                </motion.div>
                <motion.div
                  className="brand_rotation_con"
                  initial={{
                    rotate: 10,
                    x: 80,
                    y: 20,
                  }}
                >
                  <NewBrand1
                    className="brand_rotation"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "80px",
                      transform: "rotate(15.3deg)",
                    }}
                  />
                </motion.div>
                <motion.div className="brand_rotation_con">
                  <NewBrand2
                    className="brand_rotation num_1"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "80px",
                    }}
                  />
                </motion.div>
                <motion.div
                  className="brand_rotation_con"
                  initial={{
                    rotate: 100,
                    x: 280,
                    y: 230,
                  }}
                >
                  <NewBrand3
                    className="brand_rotation num_2"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "80px",
                      transform: "rotate(-15.3deg)",
                    }}
                  />
                </motion.div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              transform: "scale(1.2)",
            }}
            id="start_id_media_statistic_animation"
          >
            <>
              <motion.div
                initial={{
                  scale: 1,
                }}
                // animate={{
                //   scale: 1,
                //   transition: {
                //     duration: 0.7,
                //     ease: "easeInOut",
                //   },
                // }}
                style={{ position: "relative", top: "0", left: "0" }}
              >
                <BrandIcon4
                  style={{ position: "relative", top: "0", left: "0" }}
                />
              </motion.div>
              <div>
                <motion.div
                  className="brand_rotation_con"
                  initial={{
                    rotate: 18,
                    x: 80,
                    scale: 0.6,
                    y: 20,
                  }}
                >
                  <NewBrand1
                    className="brand_rotation"
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      width: "80px",
                      transform:
                        scrollval > 411
                          ? "rotate(19.95deg)"
                          : `rotate(${scrollval / 20}deg)`,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="brand_rotation_con"
                  initial={{
                    scale: 0.6,
                  }}
                >
                  <NewBrand2
                    className="brand_rotation num_1"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "100px",
                      width: "80px",
                    }}
                  />
                </motion.div>
                <motion.div
                  className="brand_rotation_con"
                  initial={{
                    rotate: 100,
                    x: 280,
                    scale: 0.6,
                    y: 230,
                  }}
                >
                  <NewBrand3
                    className="brand_rotation num_2"
                    style={{
                      position: "absolute",
                      top: "162px",
                      left: "-88px",
                      width: "80px",
                      transform:
                        scrollval > 411
                          ? "rotate(-19.95deg)"
                          : `rotate(${-scrollval / 20}deg)`,
                    }}
                  />
                </motion.div>
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default BrandAnimation;
