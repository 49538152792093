import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useCycle, useViewportScroll, useTransform } from "framer";
// import { Variants } from "framer-motion";

// importing the new animation
import { ReactComponent as NewJSAnimation1 } from "../../assets/new/jsAnimation/newOneJS/jsAnimation-01.svg";
import { ReactComponent as NewJSAnimation2 } from "../../assets/new/jsAnimation/newOneJS/jsAnimation-02.svg";
import { ReactComponent as NewJSAnimation3 } from "../../assets/new/jsAnimation/newOneJS/jsAnimation-03.svg";
import { ReactComponent as NewJSAnimation4 } from "../../assets/new/jsAnimation/newOneJS/jsAnimation-04-04-04.svg";
import { ReactComponent as NewJSAnimation5 } from "../../assets/new/jsAnimation/newOneJS/jsAnimation-05-05.svg";
import { ReactComponent as NewJSAnimation6 } from "../../assets/new/jsAnimation/newOneJS/jsAnimation-06.svg";
import { useInView } from "react-intersection-observer";

import gsap from "gsap";
// import { useAnimation } from "framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const WebAnimation = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);

  const [resizeval, setResizeVal] = useState(getWindowDimensions());

  useEffect(() => {
    const el = document.querySelector("#start_id_js_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      let widthValue = window.innerWidth;
      setResizeVal(widthValue);
    });
  }, []);

  var style1 = {
    position: "absolute",
    top: "0 ",
    width: "100%",
    height: "100%",
    right: scrollval / 9 + "px",
    zIndex: "10",
  };

  var style1Replace = {
    position: "absolute",
    width: "100%",
    height: "100%",
    right: "max(78.3333px)",
    zIndex: "10",
  };

  var style2 = {
    position: "absolute",
    top: "0 ",
    width: "100%",
    height: "100%",
    left: scrollval / 14 + "px",
    zIndex: "10",
  };

  var style2Replace = {
    position: "absolute",
    top: "0 ",
    width: "100%",
    height: "100%",
    left: "50px",
    zIndex: "10",
  };

  var style3 = {
    position: "absolute",
    top: scrollval / 23 + "px",
    width: "100%",
    height: "100%",
    left: "0",
    zIndex: "10",
  };

  var style3Replace = {
    position: "absolute",
    top: "30.4348px",
    width: "100%",
    height: "100%",
    left: "0",
    zIndex: "10",
  };

  var style4 = {
    position: "absolute",
    bottom: scrollval / 5.4 + "px",
    width: "100%",
    height: "100%",
    left: "0",
    zIndex: "10",
  };

  var style4Replace = {
    position: "absolute",
    bottom: "129.63px",
    width: "100%",
    height: "100%",
    left: "0",
    zIndex: "10",
  };
  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            style={{ position: "relative", width: "100%", height: "422px" }}
            id="start_id_js_animation"
          >
            {scrollval < 500 ? (
              <>
                <motion.div
                  initial={{
                    x: 80,
                  }}
                  style={{
                    position: "absolute",
                    top: "0 ",
                    width: "100%",
                    height: "100%",
                    right: scrollval / 7 + "px",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation1
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: -50,
                  }}
                  style={{
                    position: "absolute",
                    top: "0 ",
                    width: "100%",
                    height: "100%",
                    left: scrollval / 11 + "px",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation2
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0px",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: -30,
                  }}
                  style={{
                    position: "absolute",
                    top: scrollval / 18 + "px",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: 130,
                  }}
                  style={{
                    position: "absolute",
                    bottom: scrollval / 3.9 + "px",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation4
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <NewJSAnimation5
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "1",
                  }}
                />
                <NewJSAnimation6
                  style={{
                    opacity: "0.5",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "11",
                  }}
                />
              </>
            ) : (
              <>
                <motion.div
                  initial={{
                    x: 80,
                  }}
                  style={{
                    position: "absolute",
                    top: "0 ",
                    width: "100%",
                    height: "100%",
                    right: "71.4286px",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation1
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: -50,
                  }}
                  style={{
                    position: "absolute",
                    top: "0 ",
                    width: "100%",
                    height: "100%",
                    left: "45.4545px",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation2
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0px",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: -30,
                  }}
                  style={{
                    position: "absolute",
                    top: "27.7778px",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: 130,
                  }}
                  style={{
                    position: "absolute",
                    bottom: "128.205px",
                    width: "100%",
                    height: "100%",
                    left: "0",
                    zIndex: "10",
                  }}
                >
                  <NewJSAnimation4
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <NewJSAnimation5
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "1",
                  }}
                />
                <NewJSAnimation6
                  style={{
                    opacity: "0.5",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "11",
                  }}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ position: "relative", width: "100%", height: "422px" }}
            id="start_id_js_animation"
          >
            {!enter && (
              <>
                <motion.div
                  initial={{
                    x: 80,
                  }}
                  style={scrollval > 700 ? style1Replace : style1}
                >
                  <NewJSAnimation1
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: -50,
                  }}
                  style={scrollval > 700 ? style2Replace : style2}
                >
                  <NewJSAnimation2
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0px",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: -30,
                  }}
                  style={scrollval > 700 ? style3Replace : style3}
                >
                  <NewJSAnimation3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    y: 130,
                  }}
                  style={scrollval > 700 ? style4Replace : style4}
                >
                  <NewJSAnimation4
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "10",
                    }}
                  />
                </motion.div>
                <NewJSAnimation5
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "1",
                  }}
                />
                <NewJSAnimation6
                  style={{
                    opacity: "0.5",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "11",
                  }}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default WebAnimation;
