import React, { useEffect, useState } from "react";
import { ReactComponent as Svg } from "../../assets/images/serviceAnimation-01.svg";
import { ReactComponent as SvgScreen } from "../../assets/images/svgScreen.svg";
import { ReactComponent as SvgScreenF } from "../../assets/images/FrontScreen-final.svg";
import { ReactComponent as CodeSvg } from "../../assets/images/codeSVG.svg";
import { ReactComponent as FrontScreen } from "../../assets/images/FrontScreen.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { ReactComponent as CodeSvg1 } from "../../assets/images/codeSVG1.svg";
import { ReactComponent as CodeSvg2 } from "../../assets/images/codeSVG2.svg";

const ServiceAnimation = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(0);

  useEffect(() => {
    const el = document.querySelector("#start_id_code_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      let widthValue = window.innerWidth;
      setResizeVal(widthValue);
    });
  }, []);

  const codeAnimation = {
    hidden: { y: 0 },
  };

  return (
    <>
      {/* {scrollval < 600 ? ( */}
      <>
        <div style={{ position: "relative" }} id="start_id_code_animation">
          <SvgScreen style={{ position: "absolute", top: "0", left: "0" }} />
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "-13px",
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <motion.div
              variants={codeAnimation}
              initial="hidden"
              animate={{
                y: -1250,
                transition: {
                  duration: 75,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "linear",
                },
              }}
            >
              <CodeSvg2
                className="codeAnimation"
                style={{
                  position: "absolute",
                  top: "80px",
                  left: "131px",
                  width: "198px",
                }}
              />
            </motion.div>
          </div>
          <Svg
            style={{
              position: "relative",
              top: "0",
              left: "0",
              zIndex: "100",
            }}
          />
          {!enter && (
            <motion.div>
              <SvgScreenF
                id="getFrontScreen"
                style={{
                  position: "absolute",
                  top: "0px",
                  left: scrollval / 9 + "px",
                  zIndex: "200",
                  width: "100%",
                }}
              />
            </motion.div>
          )}
        </div>
      </>
      {/* ) : ( */}
      <>
        {/* <div style={{ position: "relative" }} id="start_id_code_animation">
            <SvgScreen style={{ position: "absolute", top: "0", left: "0" }} />
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <motion.div
                variants={codeAnimation}
                initial="hidden"
                animate={{
                  y: -1250,
                  transition: {
                    duration: 75,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  },
                }}
              >
                <CodeSvg2
                  className="codeAnimation"
                  style={{
                    position: "absolute",
                    top: "80px",
                    left: "131px",
                    width: "198px",
                  }}
                />
              </motion.div>
            </div>
            <Svg
              style={{
                position: "relative",
                top: "0",
                left: "0",
                zIndex: "100",
              }}
            />
            {!enter && (
              <motion.div>
                <FrontScreen
                  id="getFrontScreen"
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    zIndex: "200",
                    transform: "rotateY(50deg) translateX(85.7143px)",
                  }}
                />
              </motion.div>
            )}
          </div> */}
      </>
      {/* )} */}
    </>
  );
};

export default ServiceAnimation;
