import React from "react";
import CounterSection from "../counterSection/counter";
import SocialMediaStatistic from "../osgModifications/socialMedia/SocialMediaStatistic";
import { Link } from "react-router-dom";

const FeatureOne = () => {
  return (
    <section id="features" className="section features-area ptb_100">
      <div className="shapes-container">
        <div className="shape bg-shape" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="features-text">
              {/* Headings */}
              <div className="headings d-flex align-items-center mb-4">
                <span className="text-uppercase d-none d-sm-block">
                  Explore
                </span>
                <h2 className="text-capitalize">
                  Our digital marketing & content creation services
                </h2>
              </div>
              <p className="my-3">
                No matter how niche or how wide your audience is our team will
                take your brand and carefully craft a market-penetrating
                strategy, engaging content, and breathtaking visuals to rapidly
                increase your growth, reach, and success. Our team will work
                hard ensuring that your journey with us is as smooth and as
                successful as possible.
              </p>
              {/* Counter List */}
              <Link
                to="/media"
                style={{
                  margin: "1rem 0",
                  padding: "0.5rem 2rem ",
                  border: "2px solid #444444",
                  borderRadius: "50px",
                  fontWeight: "600",
                }}
              >
                Learn More
              </Link>
              <CounterSection />
            </div>
          </div>
          <div
            className="col-12 col-md-6 col-lg-5 offset-lg-1"
            style={{ marginLeft: "0" }}
          >
            {/* Featured Items */}
            <SocialMediaStatistic />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureOne;
