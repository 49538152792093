import React, { useEffect } from "react";
import ScrollupSection from "../scrollupSection/scrollUp";
import Header from "../headerSection/headerTwo";
import BreadcrumbSection from "../breadcrumbSection/breadcrumbOne";
import FooterSection from "../footerSection/footer";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import MediaServices from "../../components/software/MediaServices";
import SocialMediaVideos from "../osgModifications/socialMedia/SocialMediaVideos";
import BrandAnimation from "../osgModifications/socialMedia/BrandAnimation";
import SocialMediaChart from "../osgModifications/socialMedia/SocialMediaChart";
import Writecontent from "../osgModifications/socialMedia/WrightContent";
import StrategyAnimation from "../osgModifications/socialMedia/StrategyAnimation";

const Mediatitles = [
  {
    name: "Brand Identity",
    text: "The first step to any marketing strategy is creating your brand and it isn’t an easy task, to create something original yet beautiful while at the same time making sure that it tracks with your products and your audience, that takes creativity, art, and hard work, we want to provide those for you to prepare your brand to be embraced by your desired audience as well as the whole world",
    tag1: "LOGO CREATION",
    tag2: "COLOR IDENTITY",
    tag3: "CHARACTER SKETCH",
    tag4: "CREATING PRINTABLES",
  },
  {
    name: "Social Media Strategy",
    text: "The first step to any marketing strategy is creating your brand and it isn’t an easy task, to create something original yet beautiful while at the same time making sure that it tracks with your products and your audience, that takes creativity, art, and hard work, we want to provide those for you to prepare your brand to be embraced by your desired audience as well as the whole world",
    tag1: "COMPETITOR RESEARCH",
    tag2: "CUSTOMER AVATAR",
    tag3: "CONTENT PLAN",
    tag4: "Content CALENDAR",
  },
  {
    name: "Creating Visuals",
    text: "Alright, now we get our hands dirty creating the most engaging and pretty visuals to make sure that we communicating your message to your customer becomes an easy task letting you tap into the infinite potential of the world market - Graphic Design Video Editing",
    tag1: "Graphic Design",
    tag2: "Video Editing",
    tag3: "Character Creation",
    tag4: "",
  },
  {
    name: "Writing Content",
    text: "No matter how great of visuals you have, the power of the written word still has its edge, relaying an appealing & strong message to your audience and the world with a few words is not an easy feat, but we do take pride in being darn good at it!",
    tag1: "SEO",
    tag2: "Captions",
    tag3: "Blogs",
    tag4: "Articles",
  },
  {
    name: "Campaigns",
    text: "You need growth for your business, whether it’s in the form of sales, calls, visits, or any other form you want. The best way to get there is through running campaigns through social media, our team works hard towards making sure you get the best results out of the marketing budget by targeting exactly the right market segment and using all the tools in our creative arsenal to make the most effective ads we can.",
    tag1: "Advertising",
    tag2: "Social Media Boosting",
    tag3: "Product Campaigns",
    tag4: "",
  },
];

const Media = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="italy">
      <ScrollupSection />
      <div className="all-area bg-gray">
        <Header imageData={"/img/logo-white.png"} outTheme="white" />
        <section
          id="home"
          style={{ height: "22vh" }}
          className="section welcome-area d-flex align-items-center pt-0"
        >
          <div className="container"></div>
          <div className="shapes-container">
            <div className="wave-animation">
              {/* Wave Animation */}
              <svg
                className="waves"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
              >
                <defs>
                  <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                  />
                </defs>
                <g className="parallax">
                  <use
                    xlinkHref="#gentle-wave"
                    x={48}
                    y={0}
                    fill="rgba(255,255,255,0.7"
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x={48}
                    y={3}
                    fill="rgba(255,255,255,0.5)"
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x={48}
                    y={5}
                    fill="rgba(255,255,255,0.3)"
                  />
                  <use xlinkHref="#gentle-wave" x={48} y={7} fill="#f6f9fe" />
                </g>
              </svg>
            </div>
          </div>
        </section>

        <VerticalTimeline
          animate={false}
          lineColor="#97A7B5"
          layout="1-column-left"
        >
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "#ffffff",
              color: "#fff",
              borderRadius: "15px",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
            iconStyle={{ background: "#ffffff", color: "#fff" }}
            // icon={<WorkIcon />}
          >
            <div style={{ backgroundColor: "#fff", padding: "2rem 0" }}>
              <MediaServices
                title={Mediatitles[0].name}
                body={Mediatitles[0].text}
                isReversed={false}
                tag1={Mediatitles[0].tag1}
                tag2={Mediatitles[0].tag2}
                tag3={Mediatitles[0].tag3}
                tag4={Mediatitles[0].tag4}
                ImageComp={<BrandAnimation />}
              />
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{
              background: "#ffffff",
              color: "#fff",
            }}
            style={{
              paddingRight: "0",
            }}
            // icon={<WorkIcon />}
          >
            <div style={{ backgroundColor: "#fff", padding: "2rem 0" }}>
              <MediaServices
                title={Mediatitles[1].name}
                body={Mediatitles[1].text}
                isReversed={false}
                tag1={Mediatitles[1].tag1}
                tag2={Mediatitles[1].tag2}
                tag3={Mediatitles[1].tag3}
                tag4={Mediatitles[1].tag4}
                ImageComp={<StrategyAnimation />}
              />
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{ background: "#ffffff", color: "#fff" }}
            // icon={<WorkIcon />}
          >
            <div style={{ backgroundColor: "#fff", padding: "2rem 0" }}>
              <MediaServices
                title={Mediatitles[2].name}
                body={Mediatitles[2].text}
                isReversed={false}
                tag1={Mediatitles[2].tag1}
                tag2={Mediatitles[2].tag2}
                tag3={Mediatitles[2].tag3}
                // tag4={Mediatitles[2].tag4}
                // ImageComp={<SocialMediaChart />}
                ImageComp={<SocialMediaVideos />}
              />
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            // date="2006 - 2008"
            iconStyle={{ background: "#ffffff", color: "#fff" }}
            // icon={<WorkIcon />}
          >
            <div style={{ backgroundColor: "#fff", padding: "2rem 0" }}>
              <MediaServices
                title={Mediatitles[3].name}
                body={Mediatitles[3].text}
                isReversed={false}
                tag1={Mediatitles[3].tag1}
                tag2={Mediatitles[3].tag2}
                tag3={Mediatitles[3].tag3}
                tag4={Mediatitles[3].tag4}
                ImageComp={<Writecontent />}
              />
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            // date="2006 - 2008"
            iconStyle={{ background: "#ffffff", color: "#fff" }}
            // icon={<WorkIcon />}
          >
            <div style={{ backgroundColor: "#fff", padding: "2rem 0" }}>
              <MediaServices
                title={Mediatitles[4].name}
                body={Mediatitles[4].text}
                isReversed={false}
                tag1={Mediatitles[4].tag1}
                tag2={Mediatitles[4].tag2}
                tag3={Mediatitles[4].tag3}
                ImageComp={<SocialMediaChart />}
              />
            </div>
          </VerticalTimelineElement>
        </VerticalTimeline>
        <FooterSection />
      </div>
    </div>
  );
};

export default Media;
