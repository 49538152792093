import React, { useEffect, useRef, useState } from "react";
import ease_of_use from "../../assets/home-json-icons/software/click.json";
import reliability from "../../assets/home-json-icons/software/reliability.json";
import security from "../../assets/home-json-icons/software/security.json";
import innovation from "../../assets/home-json-icons/software/innovation.json";
import Lottie from "lottie-react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

const Counter = () => {
  const [handleA, setHandleA] = useState(10);
  const [scrollval, setScrollVal] = useState(0);
  const [remove, setRemove] = useState(true);

  useEffect(() => {
    setTimeout(() => {}, 1000);
  }, []);

  const handleHoverToAnimate = (index) => {
    setHandleA(index);
  };
  // 🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆🦆

  const doSomething = () => {
    setTimeout(() => {
      setRemove(false);
    }, 2500);
  };

  var style1 = {
    width: "50px",
    height: "50px",
    margin: "auto",
    display: "block",
  };

  var style2 = {
    width: "50px",
    height: "50px",
    margin: "auto",
    display: "none",
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  return (
    <div className="counter-list" style={{ paddingTop: "1rem" }}>
      <ul>
        <li
          onMouseOver={() => handleHoverToAnimate(0)}
          onMouseLeave={() => handleHoverToAnimate(10)}
        >
          {/* Single Counter */}
          <div className="single-counter px-3 py-3 text-center">
            {/* Counter Icon */}
            <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
              {scrollval > 100 ? (
                <>
                  <i style={{ maxHeight: "80px" }}>
                    <>
                      {remove && (
                        <Player
                          autoplay
                          src={ease_of_use}
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "auto",
                          }}
                          onEvent={(event) => {
                            if (event === "complete") {
                              doSomething();
                            }
                          }}
                        ></Player>
                      )}
                      <Lottie
                        autoplay={scrollval < 100 ? false : true}
                        loop={handleA === 0 ? true : false}
                        animationData={ease_of_use}
                        style={!remove ? style1 : style2}
                      />
                    </>
                  </i>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Counter Text */}
            <div className="counter-text">
              <h5>Ease of use</h5>
            </div>
          </div>
        </li>
        <li
          onMouseOver={() => handleHoverToAnimate(1)}
          onMouseLeave={() => handleHoverToAnimate(10)}
        >
          {/* Single Counter */}
          <div className="single-counter px-3 py-3 text-center">
            {/* Counter Icon */}
            <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
              {scrollval > 100 ? (
                <>
                  <i style={{ maxHeight: "80px" }}>
                    <>
                      {remove && (
                        <Player
                          autoplay
                          src={reliability}
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "auto",
                          }}
                          onEvent={(event) => {
                            if (event === "complete") {
                              doSomething();
                            }
                          }}
                        ></Player>
                      )}
                      <Lottie
                        autoplay={scrollval < 100 ? false : true}
                        loop={handleA === 1 ? true : false}
                        animationData={reliability}
                        style={!remove ? style1 : style2}
                      />
                    </>
                  </i>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Counter Text */}
            <div className="counter-text">
              <h5>Reliability</h5>
            </div>
          </div>
        </li>
        <li
          onMouseOver={() => handleHoverToAnimate(2)}
          onMouseLeave={() => handleHoverToAnimate(10)}
        >
          {/* Single Counter */}
          <div className="single-counter px-3 py-3 text-center">
            {/* Counter Icon */}
            <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
              {scrollval > 100 ? (
                <>
                  <i style={{ maxHeight: "80px" }}>
                    <>
                      {remove && (
                        <Player
                          autoplay
                          src={security}
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "auto",
                          }}
                          onEvent={(event) => {
                            if (event === "complete") {
                              doSomething();
                            }
                          }}
                        ></Player>
                      )}
                      <Lottie
                        autoplay={scrollval < 100 ? false : true}
                        loop={handleA === 2 ? true : false}
                        animationData={security}
                        style={!remove ? style1 : style2}
                      />
                    </>
                  </i>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Counter Text */}
            <div className="counter-text">
              <h5>Security</h5>
            </div>
          </div>
        </li>
        <li
          onMouseOver={() => handleHoverToAnimate(3)}
          onMouseLeave={() => handleHoverToAnimate(10)}
        >
          {/* Single Counter */}
          <div className="single-counter px-3 py-3 text-center">
            {/* Counter Icon */}
            <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
              {scrollval > 100 ? (
                <>
                  <i style={{ maxHeight: "80px" }}>
                    <>
                      {remove && (
                        <Player
                          autoplay
                          src={innovation}
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "auto",
                          }}
                          onEvent={(event) => {
                            if (event === "complete") {
                              doSomething();
                            }
                          }}
                        ></Player>
                      )}
                      <Lottie
                        autoplay={scrollval < 100 ? false : true}
                        loop={handleA === 3 ? true : false}
                        animationData={innovation}
                        style={!remove ? style1 : style2}
                      />
                    </>
                  </i>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* Counter Text */}
            <div className="counter-text">
              <h5>Innovation</h5>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Counter;
