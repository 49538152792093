import React, { Component, useEffect, useState } from "react";
import ScrollupSection from "../scrollupSection/scrollUp";
import Header from "../headerSection/headerTwo";
import BreadcrumbSection from "../breadcrumbSection/breadcrumbOne";
import Skills from "../innerSection/skills/skills";
import About from "../innerSection/about/about";
import TeamSection from "../teamSection/teamOne";
import SubscribeSection from "../subscribeSection/subscribeOne";
import ContactSection from "../contactSection/contact";
import Map from "../innerSection/map/map";
import FooterSection from "../footerSection/footer";
import axios from "axios";
import SoftwareServices from "./SoftwareServices";
import WebAnimation from "../osgModifications/AnimationJS";
import Animation3 from "../osgModifications/Animation3";
import Animation4 from "../osgModifications/Animation4";
import ServiceAnimationCloud from "../osgModifications/ServiceAnimationCloud";
import ServiceAnimation from "../osgModifications/ServiceAnimation";
import UserAnimation from "../osgModifications/UserAnimation";
import SocialMediaChart from "../osgModifications/socialMedia/SocialMediaChart";
import SocialMediaVideos from "../osgModifications/socialMedia/SocialMediaVideos";
import MobileAppAnimation from "../osgModifications/MobileAppAnimation";
import WelcomeNine from "../welcomeSection/welcomeNine";
import { ReactComponent as Dfgj } from "../../assets/new/brand/ikujynhgbvfdc-01.svg";

import Scrollbar from "react-smooth-scrollbar";
const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json/themeOnePromoSection";

const initData1 = {
  heading: "Elegant Interface, Delightful experience",
  headingText:
    "Bring your online presence to life through attractive, simple, and original Web or Mobile Apps",
};

const teamData1 = [
  {
    image: "/img/avatar_1.png",
    name: "Junaid Hasan",
    position: "Co-Founder & CEO",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.",
  },
  {
    image: "/img/avatar_2.png",
    name: "Jassica William",
    position: "Web Developer",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.",
  },
  {
    image: "/img/avatar_3.png",
    name: "John Deo",
    position: "ISO Developer",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.",
  },
  {
    image: "/img/avatar_4.png",
    name: "Johnson Smith",
    position: "Android Developer",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.",
  },
  {
    image: "/img/avatar_5.png",
    name: "Md. Arham",
    position: "Graphic Designer",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.",
  },
];

const socialData1 = [
  {
    iconClass: "fab fa-facebook-f",
  },
  {
    iconClass: "fab fa-twitter",
  },
  {
    iconClass: "fab fa-google-plus-g",
  },
  {
    iconClass: "fab fa-instagram",
  },
  {
    iconClass: "fab fa-linkedin-in",
  },
];

const Software = () => {
  const [promoDataThree, setPromoDataThree] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
        setPromoDataThree(res.data.promoDataThree);
      })
      .catch((err) => console.log(err));
  }, []);

  const [initData, setInitData] = useState({});
  const [data, setData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [scrollval, setScrollVal] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY / 10;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    setInitData(initData1);
    setData(teamData1);
    setSocialData(socialData1);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Scrollbar damping={0.1}>
        <div className="italy" id="myscrollbar">
          <div className="all-area">
            <Header imageData={"/img/logo-white.png"} outTheme="white" />
            <section
              id="home"
              style={{ height: "22vh", position: "relative", zIndex: " 200" }}
              className="section welcome-area d-flex align-items-center pt-0"
            >
              <div className="container"></div>
              <div className="shapes-container">
                <div className="wave-animation">
                  {/* Wave Animation */}
                  <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                  >
                    <defs>
                      <path
                        id="gentle-wave"
                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                      />
                    </defs>
                    <g className="parallax">
                      <use
                        xlinkHref="#gentle-wave"
                        x={48}
                        y={0}
                        fill="rgba(255,255,255,0.7"
                      />
                      <use
                        xlinkHref="#gentle-wave"
                        x={48}
                        y={3}
                        fill="rgba(255,255,255,0.5)"
                      />
                      <use
                        xlinkHref="#gentle-wave"
                        x={48}
                        y={5}
                        fill="rgba(255,255,255,0.3)"
                      />
                      <use xlinkHref="#gentle-wave" x={48} y={7} fill="#fff" />
                    </g>
                  </svg>
                </div>
              </div>
            </section>

            <div className="row justify-content-center mt-5 fidex_styles">
              <div className="col-12 col-md-10 col-lg-6 mt-5">
                {/* Section Heading */}
                <div className="section-heading text-center">
                  <h2 style={{ fontSize: "50px" }} className="text-capitalize">
                    {initData.heading}
                  </h2>
                  <p
                    style={{ fontSize: "20px" }}
                    className="d-none d-sm-block mt-4"
                  >
                    {initData.headingText}
                  </p>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: "#f6f9fe", padding: "2rem 0" }}>
              <SoftwareServices
                title={"Websites – Web Applications"}
                body={
                  "Creating an attractive and original website or web application with billions of websites being out there is not easy, however we take on that mission for you aiming for a unique Layout, breathtaking visuals, and content that users just want to interact with."
                }
                isReversed={false}
                ImageComp={<WebAnimation />}
              />
            </div>

            <div style={{ padding: "2rem 0" }}>
              <SoftwareServices
                title={"Hybrid Mobile Apps"}
                body={
                  "The number 1 way to reach the largest number of people is smartphones, the most widely used device in the world at the moment, Bringing your online presence through mobile applications can be quite expensive, However, with our team of engineers and designers and with the aid of latest technology in cross platform programing we will bring your app to users without the hassle or the cost of supporting and developing two or more different platforms while still maintaining the quality and beauty of a sleek UI and a smooth UX"
                }
                isReversed={true}
                ImageComp={<UserAnimation />}
              />
            </div>

            <div style={{ backgroundColor: "#f6f9fe", padding: "2rem 0" }}>
              <SoftwareServices
                title={"Native Mobile apps"}
                body={
                  "When cross platform technologies just are not cutting it for your application and your vision our engineers will be working hard building your apps no matter how complex or difficult to achieve they are, making sure we aid you throughout the process of planning, building,testing as well as after the fact to ensure that your app is functioning exceedingly well above industry standards"
                }
                isReversed={false}
                ImageComp={<MobileAppAnimation />}
              />
            </div>

            <div className="row justify-content-center mt-5">
              <div className="col-12 col-md-10 col-lg-6 mt-5">
                {/* Section Heading */}
                <div className="section-heading text-center">
                  <h2 style={{ fontSize: "50px" }} className="text-capitalize">
                    Smart solutions tailored for your enterprise
                  </h2>
                  <p
                    style={{ fontSize: "20px" }}
                    className="d-none d-sm-block mt-4"
                  >
                    Establish your organization from the ground up online, built
                    on Practical, Reliable, and Effective engineering
                  </p>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: "#f6f9fe", padding: "2rem 0" }}>
              <SoftwareServices
                title={"Enterprise wide software"}
                body={
                  "Having reliable, robust, And easy to use software to support your enterprise is not as easy as it should be, but our team has got you covered. We provide solutions for your enterprise to make your life and the lives of your team much easies."
                }
                isReversed={false}
                ImageComp={<Animation3 />}
              />
            </div>

            <div style={{ padding: "2rem 0" }}>
              <SoftwareServices
                title={"Custom software for your custom enterprise needs"}
                body={
                  "Business today is not geographically contained as it once was, and as business owners, managers, or team leaders we always want to be in the loop, even with remote work increasing in popularity, you need an agile and flexible cloud based solution to support your organizations however it moves and wherever it is"
                }
                isReversed={true}
                ImageComp={<ServiceAnimationCloud />}
              />
            </div>
            <FooterSection />
          </div>
        </div>
      </Scrollbar>

      <ScrollupSection />
    </>
  );
};

export default Software;
