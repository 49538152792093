import React, { useEffect, useState } from "react";
import LogoLight from "../../assets/images/white_logo.png";
import LogoDark from "../../assets/images/black_logo.png";

const HeaderTwo = ({ ourTheme, home }) => {
  const [scrollval, setScrollVal] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  var styles = {
    marginTop: "1.5rem",
  };
  var styles1 = {
    marginTop: "0rem",
  };

  return (
    <header className="section header-area">
      <div
        id="appo-header"
        className={
          ourTheme == "black"
            ? "main-header-area nav-black"
            : "main-header-area nav-white"
        }
        style={scrollval < 100 ? styles : styles1}
      >
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md navbar-light">
            {/* Logo */}
            {home === "home" ? (
              <>
                {scrollval > 100 ? (
                  <>
                    <a className="navbar-brand" href="#">
                      <img
                        className="logo"
                        src={ourTheme == "black" ? LogoDark : LogoLight}
                        alt=""
                      />
                    </a>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <a className="navbar-brand" href="/">
                  <img
                    className="logo"
                    src={ourTheme == "black" ? LogoDark : LogoLight}
                    alt=""
                  />
                </a>
              </>
            )}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#appo-menu"
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Nav Menu */}
            <div className="collapse navbar-collapse" id="appo-menu">
              {/* Header Items */}
              <ul className="navbar-nav header-items ml-auto">
                <a
                  className="nav-link brandish-font"
                  href="/"
                  id="navbarDropdown"
                  style={{ padding: "0 1rem" }}
                >
                  Home
                </a>
                <a
                  className="nav-link brandish-font"
                  href="/software"
                  id="navbarDropdown"
                  style={{ padding: "0 1rem" }}
                >
                  Software Services
                </a>

                <a
                  className="nav-link brandish-font"
                  href="/media"
                  id="navbarDropdown"
                  style={{ padding: "0 1rem" }}
                >
                  Media Services
                </a>

                {/* <a
                  className="nav-link brandish-font"
                  href="/blog-two-column"
                  id="navbarDropdown"
                  style={{ padding: "0 1rem" }}
                >
                  Blog
                </a> */}

                <li className="nav-item">
                  <a
                    className="nav-link brandish-font"
                    href="/#contact"
                    id="navbarDropdown"
                    style={{ padding: "0 1rem" }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
