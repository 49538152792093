import React, { useEffect, useState } from "react";
import { ReactComponent as ConvIcon1 } from "../../../assets/new/ConvAnimation/ConvAnimation-01.svg";
import { ReactComponent as ConvIcon2 } from "../../../assets/new/ConvAnimation/ConvAnimation-02.svg";
import { ReactComponent as ConvIcon3 } from "../../../assets/new/ConvAnimation/ConvAnimation-03.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const StrategyAnimation = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());

  useEffect(() => {
    const el = document.querySelector("#start_id_media_conv_animation");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  return (
    <>
      {resizeval.width > 991 ? (
        <>
          <div
            style={{ position: "relative", height: "422px" }}
            id="start_id_media_conv_animation"
          >
            {scrollval < 1000 ? (
              <>
                {!enter && (
                  <>
                    <ConvIcon1
                      style={{ position: "relative", top: "0", left: "0" }}
                    />
                    <motion.div
                      initial={{
                        x: -90,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: scrollval / 11 + "px",
                      }}
                    >
                      <ConvIcon2
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          zIndex: "100",
                        }}
                      />
                    </motion.div>
                    <motion.div
                      initial={{
                        x: 90,
                      }}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        right: scrollval / 11 + "px",
                      }}
                    >
                      <ConvIcon3
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                    </motion.div>
                  </>
                )}
              </>
            ) : (
              <>
                <ConvIcon1
                  style={{ position: "relative", top: "0", left: "0" }}
                />
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                  }}
                >
                  <ConvIcon2
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "100",
                    }}
                  />
                </motion.div>
                <motion.div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                  }}
                >
                  <ConvIcon3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                </motion.div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ position: "relative", transform: "scale(1.3)" }}
            id="start_id_media_conv_animation"
          >
            <ConvIcon1 style={{ position: "relative", top: "0", left: "0" }} />
            <motion.div
              initial={{
                x: -150,
              }}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left:
                  scrollval > 1500 ? "max(136.727px)" : scrollval / 11 + "px",
              }}
            >
              <ConvIcon2
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: "100",
                }}
              />
            </motion.div>
            <motion.div
              initial={{
                x: 150,
              }}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                right:
                  scrollval > 1500 ? "max(136.727px)" : scrollval / 11 + "px",
              }}
            >
              <ConvIcon3
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              />
            </motion.div>
          </div>
        </>
      )}
    </>
  );
};

export default StrategyAnimation;
