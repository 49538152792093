import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast, Slide } from "react-toastify";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneContactSection";

const ContactSection = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_95cqr3g",
        "template_xq0r0q3",
        form.current,
        "nIHX6V3lUkbXg2SCy"
      )
      .then(
        (result) => {
          toast.success("Email Send Successfully");
        },
        (error) => {
          toast.error("Email Didn't Send");
        }
      );
  };

  return (
    <>
      <ToastContainer
        style={{ boxShadow: "none" }}
        newestOnTop={true}
        hideProgressBar={true}
      />
      <section id="contact" className="contact-area bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{data.heading}</h2>
                {/* <p className="d-none d-sm-block mt-4">{data.headingText}</p> */}
                <p className="d-block d-sm-none mt-4">{data.headingTextTwo}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Contact Box */}
              <div className="contact-box text-center">
                {/* Contact Form */}
                <form
                  id="contact-form"
                  className="form"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          style={{ borderRadius: "10px" }}
                          type="text"
                          className="form-control"
                          name="from_name"
                          placeholder="Name"
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          style={{ borderRadius: "10px" }}
                          type="email"
                          className="form-control"
                          name="from_email"
                          placeholder="Email"
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          style={{ borderRadius: "10px" }}
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Subject"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <textarea
                          style={{ borderRadius: "10px" }}
                          className="form-control"
                          name="message"
                          placeholder="Message"
                          required="required"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-bordered mt-3 mt-sm-4"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                {/* <p className="form-message" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
