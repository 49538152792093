import React, { useEffect, useState } from "react";
import { ReactComponent as WriteIcon1 } from "../../../assets/new/writing/theNewOne/writing-01-01-01.svg";
import { ReactComponent as WriteIcon2 } from "../../../assets/new/writing/theNewOne/writing-01-02.svg";
import { ReactComponent as WriteIcon3 } from "../../../assets/new/writing/theNewOne/writing-01.svg";
import { ReactComponent as WriteIcon4 } from "../../../assets/new/writing/theNewOne/writing-02.svg";
import { ReactComponent as WriteIcon5 } from "../../../assets/new/writing/theNewOne/writing3-03.svg";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Frame, useCycle } from "framer";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const WrightContent = () => {
  const [enter, setEnter] = useState(false);
  const [scrollval, setScrollVal] = useState(0);
  const [resizeval, setResizeVal] = useState(getWindowDimensions());
  useEffect(() => {
    const el = document.querySelector("#start_id_media_write_animation_test");
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEnter(false);
          return;
        }
        setEnter(true);
      },
      {
        root: null,
        threshold: 0,
      }
    );
    observer.observe(el);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", function () {
      setResizeVal(getWindowDimensions());
    });
  }, []);

  const [animate3, cycle3] = useCycle(
    { scale: 0.5, opacity: 0 },
    { opacity: 1, scale: 0.5 }
  );

  const [animate2, cycle2] = useCycle(
    { x: -50, opacity: 0 },
    { x: 0, opacity: 1 }
  );

  return (
    <>
      <>
        {resizeval.width > 991 ? (
          <>
            <div
              style={{ position: "relative", height: "422px" }}
              id="start_id_media_write_animation_test"
            >
              {scrollval < 1780 ? (
                <>
                  {/* {!enter && ( */}
                  <>
                    <WriteIcon5
                      style={{ position: "relative", top: "0", left: "0" }}
                    />
                    <motion.div
                      initial={{
                        x: 450,
                      }}
                      style={{
                        position: "relative",
                        top: "-105%",
                        zIndex: "400",
                        right: scrollval / 4.4 + "px",
                      }}
                    >
                      <WriteIcon2
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          zIndex: "400",
                          transform: "rotate(5deg)",
                        }}
                      />
                    </motion.div>
                  </>
                  {/* )} */}
                </>
              ) : (
                <>
                  <WriteIcon5
                    style={{ position: "relative", top: "0", left: "0" }}
                  />
                  <div
                    style={{
                      position: "relative",
                      top: "-105%",
                      zIndex: "400",
                      right: "-43px",
                    }}
                  >
                    <WriteIcon2
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        transform: "rotate(5deg)",
                      }}
                    />
                  </div>
                </>
              )}
              {scrollval < 1400 ? (
                <>
                  <motion.div
                    animate={animate3}
                    transition={{ duration: 1 }}
                    onTap={() => cycle3()}
                    style={{
                      position: "relative",
                      top: "-95%",
                      zIndex: "200",
                    }}
                  >
                    <WriteIcon4
                      style={{
                        position: "absolute",
                        // top: "0",
                        left: "0",
                        zIndex: "100",
                      }}
                    />
                  </motion.div>
                  <motion.div
                    animate={animate2}
                    transition={{ duration: 1 }}
                    onTap={() => cycle2()}
                    style={{
                      position: "relative",
                      top: "-95%",
                      zIndex: "200",
                    }}
                  >
                    <WriteIcon3
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "100",
                      }}
                    />
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                    initial={{
                      x: -50,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        duration: 1,
                      },
                    }}
                    style={{
                      position: "relative",
                      top: "-95%",
                      zIndex: "200",
                    }}
                  >
                    <WriteIcon4
                      style={{
                        position: "absolute",
                        // top: "0",
                        left: "0",
                        zIndex: "100",
                      }}
                    />
                  </motion.div>
                  <motion.div
                    initial={{
                      scale: 0.5,
                      opacity: 0,
                    }}
                    animate={{
                      scale: 1,
                      opacity: 1,
                      transition: {
                        duration: 1,
                      },
                    }}
                    style={{
                      position: "relative",
                      top: "-95%",
                      zIndex: "200",
                    }}
                  >
                    <WriteIcon3
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "100",
                      }}
                    />
                  </motion.div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <>
              <div
                style={{
                  position: "relative",
                  transform: "scale(1.3)",
                  marginTop: "4rem",
                }}
                id="start_id_media_write_animation_test"
              >
                <WriteIcon5
                  style={{ position: "relative", top: "0", left: "0" }}
                />
                <motion.div
                  initial={{
                    x: 800,
                  }}
                  style={{
                    top: "0",
                    zIndex: "400",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    right:
                      scrollval > 3378
                        ? "max(804.286px)"
                        : scrollval / 4.2 + "px",
                  }}
                >
                  <WriteIcon2
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      zIndex: "400",
                      transform: "rotate(5deg)",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    x: 0,
                    y: 0,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 1,
                    },
                  }}
                  style={{
                    top: "0",
                    zIndex: "200",
                    width: " 100%",
                    height: "100%",
                  }}
                >
                  <WriteIcon4
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "100",
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1,
                    },
                  }}
                  style={{
                    top: "0",
                    width: " 100%",
                    height: "100%",
                    zIndex: "200",
                  }}
                >
                  <WriteIcon3
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "100",
                    }}
                  />
                </motion.div>
              </div>
            </>
          </>
        )}
      </>
    </>
  );
};
export default WrightContent;
